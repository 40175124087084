import React from 'react'
import styled from 'styled-components'

interface IProps {
  title: string,
  message?: string,
  children?: any
}

const Wrapper = styled.div`
  color: #fff;
  height: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  background-color: #341644;
  max-width: 660px;
  width: 100%;
  padding: 30px;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: inset 0 5px 2px -2px #531d75, 0 10px 2px -2px #0000004d;
  a {
    color: inherit;
  }
`

const Title = styled.div`
  font-family: 'Rammetto One';
  font-size: 22px;
  margin-bottom: 30px;
`

const Message = styled.div``

const ErrorPage = (props: IProps) => {
  const { title, message, children } = props

  return <Wrapper>
    <Title>{title}</Title>
    <Content>
      { message && <Message>{message}</Message> }
      { children }
    </Content>
  </Wrapper>
}

ErrorPage.defaultProps = {
  message: null,
  children: null
}

export default ErrorPage
