import ErrorPage from 'components/pages/ErrorPage'
import { navigate } from 'gatsby'
import Base from 'layouts/base'
import { Button } from 'nzk-react-components'
import React, { useEffect } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'
import styled from 'styled-components'

const Content = styled.div`
  * { margin-bottom: 20px; }
  > :last-child {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    margin-bottom: 0;
  }
`
const AccountIssue = () => {
  const { logout } = useCurrentUserState()

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Sentry) {
      window.Sentry.captureException(new Error('Account issue'))
    }
  }, [])

  const onLogout = async () => {
    await logout()
    navigate('/login')
  }

  return <ErrorPage title='Uh oh!'>
    <Content>
      <div>There appears to be an issue with your account.</div>
        <div>Please contact <a href="mailto:support@nightzookeeper.com">
        support@nightzookeeper.com
      </a> and the team will be happy to assist you.</div>
      <div>
        <Button size='regular' theme='primary' onClick={onLogout}>Logout</Button>
      </div>
    </Content>
  </ErrorPage>
}

const AccountIssuePage = (props) => {
  if (typeof window === 'undefined') return null

  return <Base>
    <AccountIssue {...props} />
  </Base>
}

export default AccountIssuePage
